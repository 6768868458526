<template>
  <img class="CouponHeroHeader" src="~/assets/img/list/header-main.svg" alt="メルペイクーポン" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style scoped>
.CouponHeroHeader {
  object-fit: contain;
  width: 100%;
  vertical-align: top;
}
</style>
