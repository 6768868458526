<template>
  <div class="InfoPage">
    <coupon-hero-header />
    <div class="InfoPage__Body">
      <p class="InfoDescription">クーポンはアプリからのみご利用いただけます</p>
      <div class="InfoQr__Container">
        <div class="InfoQr__ImageWrapper">
          <vue-qrcode value="https://coupon.merpay.com" :options="{ width: 192 }" />
        </div>
        <p class="InfoQr__Description">こちらのQRコードを読み取るとアプリ内でクーポンを確認できます</p>
        <p class="InfoQr__Notice">※必ずフリマアプリ「メルカリ」がインストールされている端末で読み取ってください</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import CouponHeroHeader from '~/components/CouponHeroHeader.vue';

export default defineComponent({
  components: {
    VueQrcode,
    CouponHeroHeader,
  },
  head() {
    return {
      title: '「メルカリ」アプリを開いて「メルペイ」をチェック',
    };
  },
});
</script>

<style scoped>
.InfoPage {
  color: #333;
  text-align: center;
}

.InfoPage__Body {
  margin-top: 32px;
  padding: 0 16px;
}

.InfoDescription {
  font-size: 14px;
  font-weight: bold;
}

.InfoQr__Container {
  display: inline-block;
  margin-top: 32px;
  padding: 16px 16px 32px;
  border: solid 1px #333;
}

.InfoQr__Description {
  font-size: 14px;
  font-weight: bold;
}

.InfoQr__Notice {
  margin-top: 16px;
  font-size: 12px;
}
</style>
